import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

import { AuthProvider } from './routes/auth';

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))

function App() {
  return (
    <>
      <AuthProvider>
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route path="/login/:status?" component={Login} />
            <Route path="/app" component={Layout} />
            <Redirect exact from="/" to="/login" />
          </Switch>
        </Router>
      </AuthProvider>
    </>
  )
}

export default App
